var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resolve-from-condition-view"},[(_vm.model instanceof _vm.ResolveFromRankCondition)?_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'options': _vm.ranksOptions,
        'clearable': false,
        'prefix': 'resolve_from_rank_condition_'
      }
    }}}):_c('number-input',{attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'max': Number.MAX_SAFE_INTEGER,
        'prefix': ((_vm.model.snakeCaseType) + "_condition_")
      }
    }}}),_c('switch-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': _vm.model,
        'key': 'work_before',
        'prefix': 'resolve_from_condition_'
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }